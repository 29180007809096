.Tasker-Icon
{
    transition: 1s all ease;
    position: absolute;
    top:7px;
    left:10px;
}

.Tasker-Icon-Opacity1
{
    opacity:1 !important;
}


.Tasker-Icon-Opacity0
{
    opacity:0 !important;
}