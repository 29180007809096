.loader-div
{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    animation: blink 1.5s linear infinite;

}

@keyframes blink{
    0%{opacity: 0.4;}
    20%{opacity: 0.6;}
    40%{opacity: 0.8;}
    60%{opacity: 1;}
    80%{opacity: 0.8}
    100%{opacity: 0.5;}
}