.Tasker-Top-Nav
{
    background: rgb(196, 196, 196);
    padding: 10px 30px;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}


.Tasker-Bottom-Nav
{
    background: grey;
    padding:20px;
    position: relative;
    height: 60px;
}

.Tasker-Add-Input
{
    display: inline-block;
    height:31px;
    width:calc(100% - 56px - 64px - 40px);
    margin-top: 12px;
}

.Tasker-Speed-Dial-Wrapper
{
    display: inline-block;
    position: relative;
    top:0px;
    margin-left: 20px;

}

.Tasker-Add-Button
{
    display:inline-block !important;
    margin-top: 0px;
    background: #10102A !important;
    color:#9c9c9c !important;
}



